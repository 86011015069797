.searchBox {
  input {
    border-radius: 0.4rem;
    padding: 0.8rem 1.2rem;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  }
  button {
    padding: 1rem;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    height: 4rem;
    cursor: pointer;

    svg {
      height: 1.6rem;
      width: 1.6rem;
    }
  }
}
