.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .location {
    margin-bottom: 0.8rem;
  }

  .map {
    flex: 1;
  }
}
