.container {
  .timeSlots {
    display: flex;
    flex-direction: column;

    .timeSlot {
      font-size: 1rem !important;
    }
  }

  .sportPara {
    font-size: 1.2rem !important;
    margin-top: 0;
    margin-bottom: 0;
  }

  .calendarSlots {
    background-color: rgb(70, 151, 237);
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 4px;
  }
}