.container {
  display: flex;

  .weekView {
    border-right: 1px solid rgba(26, 26, 26, 0.1);
    flex: 1;
  }

  .overrides {
    width: 40rem;
  }
}
