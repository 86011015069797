.container {
  background-color: #fff;
  padding: 16px;
  border-radius: 0.4rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  display: flex;
  flex-direction: column;
  border: 0.1rem solid rgb(0 0 0 / 0.05);

  .calendarName {
    margin-bottom: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
  }

  .actionButton {
    height: 4.2rem !important;
    margin-left: 1em;
  }
}

.slot {
  p {
    margin: 0px !important;
    padding: 0px !important;
    font-weight: 600;
  }
}

.slotBar {
  border-radius: 4px;
  padding: 1px 4px;
  border-width: 0px;
  // margin: -2px;
  // border:0px solid red;
  white-space: normal;
}

.slotFullyBook {
  background-color: #28c76f30;
  color: #28c76f;
}

.slotHalfBook {
  background-color: #007bff30;
  color: #007bff;
}

.slotNotBook {
  background-color: #ff9f4330;
  color: #ff9f43;
}

.slotReported {
  background-color: #eb202630;
  color: #eb2026;
}