.container {
  height: 100%;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);

  :global(.ant-tabs-top > .ant-tabs-nav) {
    margin-bottom: 0rem;
  }

  :global(.ant-tabs-content) {
    padding: 2rem;
    background: #fff;
    min-height: 40rem;
  }
}

.packageDetails {
  margin-right: 2rem;
  .packageDetail {
    display: flex;
    flex-direction: column;

    .detailTitle {
      font-size: 1.6rem !important;
      color: #313a46 !important;
      font-weight: 600 !important;
    }

    .detailValue {
      color: #6c757d;
    }
  }

  .packageDetail + .packageDetail {
    margin-top: 1.2rem;
  }
}
