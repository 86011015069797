.pageContainer {
  height: fit-content;
  padding-bottom: 2rem;
}

.customTab {
  background-color: #F1f1f1;
  padding: 8px;
  border-radius: 4px 4px 0 0;
  margin-top: 4px;
  margin-bottom: -4px;
  margin-right: 4px;
  margin-left: 4px;
  width: auto;
  color: #000;
  text-transform: capitalize;
  cursor: pointer;
}

.customTabSelected {
  background-color: #CCC !important;
  color: #ff6304;
  font-weight: 500;
}