.container {
    margin: -1rem;
    height: 500px;

    p {
        margin-bottom: 0px !important
    }
}

.progressBar {
    background-color: rgb(204, 204, 204);
    height: 8px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.progressBarFilled {
    background-image: linear-gradient(to right, #F7931E, #E35F2C);
    height: 8px;
    border-radius: 8px;
}

.textLeft {
    text-align: right;
    font-size: 12px;
    color: gray;

    strong {
        color: #F7931E;
    }
}

.timeBox {
    background-color: rgb(227, 227, 227);
    flex-direction: row;
    display: flex;
    padding: 8px;
    border-radius: 4px;
    width: auto;
    margin: 0 16px;

    .date {
        font-size: 20px;
        color: #F7931E;
        margin-left: 8px;
        font-weight: bold;
    }

    .time {
        font-size: 20px;
        color: #F7931E;
        margin-left: 8px;
        font-weight: bold;
    }

}


.divider {
    border-bottom: 0.5px solid gray;
    margin: 16px 0;
}

.img {
    float: left;
    height: 130px;
    margin: 5px;
}

.description {
    white-space: pre-wrap;
    text-align: justify;
}

.row {
    flex-direction: row;
    align-items: center;
    display: flex;
}

.rowJ {
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: center;

}


.cards {
    // width: auto;
    border: 0.5px solid #999;
    border-radius: 4px;
    width: 150px;
    height: 100px;
    margin-right: 8px;
    margin-bottom: 16px;
    flex-direction: column;
    display: flex;

    align-items: center;
    justify-content: center;

    .sportName {
        text-align: center;
        color: black;
        font-size: 18px;
    }

    .price {
        margin-top: 8px;
        text-align: center;
        color: black;
        font-size: 14px;
    }
}