.logo {
  display: flex;
  height: 6.4rem;
  align-items: center;
  padding: 2.4rem;
  img {
    height: 1.6rem;
    margin-right: 1.6rem;
  }
  .appTitle {
    color: #fff !important;
    font-size: 2rem !important;
    font-weight: 600 !important;
  }
}

.menuContainer {
  background-color: #313a46 !important;

  :global {
    .ant-menu-item-selected {
      background-color: #313a46 !important;
    }

    .ant-menu-inline.ant-menu-sub {
      background: #313a46 !important;
    }

    .ant-menu-submenu-title {
      padding: 0rem !important;
    }

    .ant-menu-submenu {
      padding: 1.2rem 0rem !important;
      .ant-menu {
        padding: 0rem;

        li {
          padding-left: 2.4rem !important;
        }

        .ant-menu-title-content {
          margin-left: 0rem;
        }
      }
    }
  }
}
