.timeDiv {
  padding-right: 2rem;
  font-size: 12px;
  width: 100%;
}

.scheduleTextArea {
  padding-right: 2.6rem;
  font-size: 12px;
  width: 100%;

}

.scheduleName {
  // max-width: 20rem;
  padding-right: 2rem;
}

.scheduleNameViewOnly {
  font-size: 2rem !important;
  font-weight: 600 !important;
}

.inputBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.hint {
  font-size: 1rem !important;
  color: #a8a8a8;
}

.select {

  :global(.ant-select-selector) {
    padding: 0.4rem 1.2rem !important;
    height: fit-content !important;
    border-radius: 0.4rem !important;
    width: 30rem !important;
  }
}

.errorInput {
  :global(.ant-select-selector) {
    border-color: #ff4d4f !important;
  }
}

.warningInput {
  :global(.ant-select-selector) {
    border-color: #faad14 !important;
  }
}

.successInput {
  :global(.ant-select-selector) {
    border-color: #52c41a !important;
  }
}