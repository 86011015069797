html {
  font-size: 10px;
  font-family: "Rajdhani" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
  font-weight: 500;
  color: #5e5873;
}

div {
  font-family: "Rajdhani" !important;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.ml-2 {
  margin-left: 16px;
}

.mr-2 {
  margin-right: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: 33.33%;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.JCB {
  justify-content: space-between;
}

.AIC {
  align-items: center;
}

.ASC {
  align-self: center;
}

.card {
  padding: 24px;
  /* margin-right: 3rem !important; */
  border: none;
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.8rem;
}

.headingContainer {
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.headingText {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  color: #5e5873;
}

.subText {
  font-size: 1.2rem;
  font-weight: 400;
  color: #b9b9c3;
}

.label {
  cursor: default;
  margin-bottom: 4px;
  color: #5e5873;
  font-size: 1.2rem;
}

.inputContainer {
  /* display: flex; */
  /* flex: 1; */
  /* width: 100%;
  background-color: red; */
}

.inputBox {
  border-radius: 0.4rem;
  padding: 0.8rem 1.2rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.subInputText {
  font-size: 1rem;
  font-weight: 400;
  /* line-height: 1.2; */
  color: #5e5873ac;
}

.capitalize {
  text-transform: capitalize;
}

.detailBox {
  margin-top: 1.2rem;
}

.detailTitle {
  font-size: 1.6rem !important;
}

.tabBarGray {
  padding: 8px;
  transition: transform .2s;
  background-color: #f9f9f9;
}

.tabBarWhite {
  padding: 8px;
  transition: transform .2s;
  background-color: white;
}

.tabBarGray:hover {
  transform: scale(1.03)
}

.tabBarWhite:hover {
  transform: scale(1.03)
}

.Graphtooltip {
  padding: 4px 8px;
  font-size: 12px;
  font-family: "Rajdhani" !important;
}


/* ///////////////////////////////////////SELECT INPUT START */

.ant-input:hover,
.ant-input-number:hover,
.inputBox:hover {
  border-color: #ff6304;
  border-right-width: 1px !important;
}

.ant-input:focus,
.ant-input-focused,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #ff6304;
  box-shadow: 0 2px 4px rgba(255, 101, 24, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}

.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #ff6304;
  border-right-width: 1px !important;
  z-index: 1;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ff6304;
  border-right-width: 1px !important;
}


.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #ff6304;
  box-shadow: 0 2px 4px rgba(255, 101, 24, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}

/* ///////////////////////////////////////SELECT INPUT END */


/* ///////////////////////////////////////RADIO BUTTON START */
.radioBtn {
  width: 100%;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: auto;
  margin: 0;
  width: 50%;
  padding: 4px;
  color: #5e5873;
  text-align: center;
  /* font-size: 14px; */
  /* line-height: 30px; */
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #ff6304;
  border-color: #ff6304;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #ff6304;
  border-color: #ff6304;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #ff6304;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #ff6304;

}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #ff6304;
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:last-child {
  border-right-color: #ff6304;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 2px 20px rgba(255, 101, 24, 0.2);
}

.ant-radio-button-wrapper:first-child {
  border-radius: 0.4rem 0 0 0.4rem;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 0.4rem 0.4rem 0;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #ff6304;
}

/* ///////////////////////////////////////RADIO BUTTON END */


@import "antd/dist/antd.css";
@import 'react-toastify/dist/ReactToastify.css';