.container {
  padding: 1.6rem;

  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 1.6rem;
  }

  .override {
    padding: 1.2rem 0rem;
    display: flex;
    border-bottom: 1px solid rgba(26, 26, 26, 0.1);
    cursor: pointer;

    .overrideDate {
      max-width: 20rem;
    }

    .overrideTimeSlots {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    .overrideActions {
      display: flex;
      justify-content: flex-end;

      .actionIcon {
        display: flex;
        font-size: 1.6rem;
        cursor: pointer;
        margin-top: 0.2rem;
      }
    }
  }

  .override:hover {
    background-color: #fafafa;
  }

  .override + .override {
    border-top: 1px solid rgba(26, 26, 26, 0.1);
  }
}

.zeroOverrideLabel {
  text-align: center;
}