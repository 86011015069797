.container {
  :global {
    .ant-radio-button-wrapper {
      border: none;
      background-color: transparent;
      border-radius: 0.2rem;
    }

    .ant-radio-button-wrapper:first-child {
      border: none;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }

    .ant-radio-group {
      padding: 0.4rem;
      background-color: rgba(26, 26, 26, 0.1);
      border-radius: 0.4rem;
    }
  }
}
