.container {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // justify-content: space-around;
  // align-items: flex-end;
}

.listActions {
  width: 100%;
  display: flex;
  padding-right: 1em;
  justify-content: flex-end;

  .actionIcon {
    width: 2.2em;
    height: 2.2em;
    color: #6c757d;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;

  }

  .actionIcon:hover {
    background-color: #fafafa;
    border-radius: 50%;
    width: 2.2em;
    height: 2.2em;
  }
}


.closeBtn {
  position: absolute;
  right: 16px
}

.imgUpload {
  width: 100%;
  flex-direction: column;
}

.slotImage {
  // width: 120px;
  margin-right: 20px;
  height: 105px;
  backface-visibility: hidden;
  object-fit: contain;
}