.container {
  display: flex;
  flex-direction: column;
    .priceHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .sportLabel {
        padding-left: 30px;
      }
      .priceLabel {
        padding-left: 60px;
      }
      .capacityLabel {
        padding-left: 60px;
      }
    }
  

  .priceList {
    flex: 1;

    .sportPrice + .sportPrice {
      margin-top: 1.2rem;
    }
  }

  .listActions {
    width: 8rem;
    padding-left: 30px;

    .actionIcon {
      font-size: 1.6rem;
      color: #6c757d;
      cursor: pointer;
      padding: 0.8rem 0.4rem;
    }

    .actionIcon + .actionIcon {
      margin-left: 0.2rem;
    }

    .actionIcon:hover {
      background-color: #fafafa;
      border-radius: 50%;
    }
  }
}

:global {
  .ant-modal-body {
    // height: 40rem;
    overflow: auto;
  }
}


.viewContainer {
  display: flex;
  flex-wrap: wrap;
}