.container {
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(26, 26, 26, 0.1);

  .weekDayDetails {
    display: flex;
    flex: 1;

    .timeSlot + .timeSlot {
      margin-top: 1.2rem;
    }

    .weekDayName {
      width: 8rem;
      font-weight: 600;
      color: #313a46;
      display: flex;
      align-items: flex-start;
    }

    .weekDayTimeSlots {
      flex: 1;
    }
  }

  .weekDayActions {
    width: 6rem;
    display: flex;
    align-items: flex-start;
    justify-content: end;

    .actionIcon {
      font-size: 1.6rem;
      color: #6c757d;
      cursor: pointer;
      padding: 0.8rem 0.4rem;
    }

    .actionIcon + .actionIcon {
      margin-left: 0.2rem;
    }

    .actionIcon:hover {
      background-color: #fafafa;
      border-radius: 50%;
    }
  }
}
