.container {
  .saveButton {
    width: 100%;
    padding: 0.8rem !important;
  }

  .select {
    :global(.ant-select-selector) {
      padding: 0.4rem 1.2rem !important;
      height: fit-content !important;
      border-radius: 0.4rem !important;
    }
  }

  :global(.ant-input-number-input) {
    padding: 0.8rem;
    height: fit-content;
    border-radius: 0.4rem;
  }

  :global(.ant-select-selector) {
    padding: 0.4rem !important;
    height: fit-content !important;
    border-radius: 0.4rem !important;

    .ant-select-selection-placeholder {
      padding-left: 0.2rem;
    }
  }
}

.PriceMessage {
  margin-top: -20px;
  margin-bottom: 20px;
  font-size: small;
  color: gray
}

.images {
  margin-bottom: 2rem;
  display: flex;  
  flex-wrap: wrap;

  .Imagecontainer {
    position: relative;
    width: 120px;
  }

  .image {
    margin-left: 0.4rem;
    opacity: 1;
    display: block;
    width: 120px;
    height: 100px;
    transition: .5s ease;
    backface-visibility: hidden;
    object-fit: cover;
  }

  .Imagemiddle {
    cursor: pointer;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .Imagecontainer:hover .image {
    opacity: 0.3;
  }

  .Imagecontainer:hover .Imagemiddle {
    opacity: 1;
  }

  .Imagetext {
    background-color: #B20000;
    color: white;
    font-size: 12px;
    padding: 8px 16px;
  }
}