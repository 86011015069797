.container {
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  min-height: 10rem;
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;

  .description {
    margin-right: 1.6rem;
    flex: 1
  }

  .bannerImage {
    max-width: 20rem;
  }
}