.container {
  display: flex;
  flex-wrap: wrap;

  .image {
    width: fit-content;
    height: fit-content;
    padding: 0.8rem;
    border: 0.1rem solid rgb(0 0 0 / 0.05);
  }
}
