.container {
  display: flex;
  align-items: center;

  .separator {
    padding: 0rem 1.2rem;
  }

  .deleteIcon {
    margin-left: 1.2rem;
    font-size: 1.6rem;
    color: gray;
    cursor: pointer;
    padding: 0.8rem;
  }

  .deleteIcon:hover {
    background-color: #fafafa;
    border-radius: 50%;
  }

  .timeSlot {
    display: flex;
  }

  .timeSlot + .timeSlot {
    margin-left: 2.4rem;
  }
}
