.pageContainer {
  height: fit-content;
  padding-bottom: 2rem;
}

.pageTitle {
  padding: 1rem 1.6rem;
  font-size: 1.6rem;
}

.pageContent {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  border-radius: 0.4rem;

  .actions {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 1.2rem;
  }
}

.icon {
  padding: 0rem 0.4rem !important;
  // pointer-events: none;

  &.iconDanger {
    color: red;
  }
}

.btnDisabled {
  padding: 0rem 0.4rem !important;
  pointer-events: none;
}