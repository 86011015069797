.container {
  background-color: #fff;
  border-radius: 0.4rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  display: flex;
  flex-direction: column;
  border: 0.1rem solid rgb(0 0 0 / 0.05);

  .scheduleHeader {
    border-bottom: 1px solid rgba(26, 26, 26, 0.1);
  }
}
