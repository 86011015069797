.container {
  p {
    text-align: center;
    font-size: 1.5rem;
  }

  .purchaseAnalytics {
    justify-content: space-between;
    .packageDetail {
      display: flex;
      flex-direction: column;
      margin: 2rem;
      justify-content: center;

      .detailTitle {
        font-size: 1.6rem !important;
        color: #313a46 !important;
        font-weight: 600 !important;
      }

      .detailValue {
        color: #6c757d;
      }
    }
  }
}
