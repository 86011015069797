.container {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

}

.container1 {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  display: flex;
  align-items: flex-end;

  .actionButton {
    height: 4.2rem !important;
  }
}