.select {
  width: 8rem;
  border-radius: 0.4rem;

  :global {
    .ant-select-selector {
      height: 4.0rem !important;
      border-radius: 0.8rem !important;
      display: flex !important;
      align-items: center !important;
      color: #6c757d !important;
    }
  }
}
