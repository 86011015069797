.container {
  display: flex;
  height: fit-content;
  flex-direction: column;

  :global(.ant-page-header) {
    padding: 2rem 0rem;
  }
}

.pageContent {
  display: flex;
  padding: 0rem 0rem 2rem 0rem;

  .locationContainer {
    background-color: #fff;
    border-radius: 0.4rem;
    width: 32rem;
    padding: 1.6rem;
  }

  .packageDetails {
    background-color: #fff;
    border-radius: 0.4rem;
    flex: 1;
    padding: 1.6rem;
    margin-left: 2rem;
  }
}

.loader {
  position: absolute;
  left: 50vw;
  top: 35vw;
  width: 15rem;
  height: 15rem;
  font-size: 4rem;
}
