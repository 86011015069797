.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  // align-items: center;
  overflow: hidden;
  background-color: #f9fafb;
}

.content {
  height: 100%;
  width: 100%;
  max-width: 120rem;
  padding: 24px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
}

.card {
  border: none;
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.275);
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.8rem;
}

.showHot {
  box-shadow: 0 4px 24px 0 #ff640469 !important;
}

.row {
  display: flex;
  flex-direction: row;
}

.left {
  width: 50rem !important;
  height: 50rem;
}

.right {
  width: 70rem;
  height: 50rem;
  padding: 24px;
  overflow: auto;
}

.logo {
  height: 4.8rem;
  margin-bottom: 2.4rem;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userImage {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-right: 16px;
  border: 0.5px solid #000;
}

.userName {
  font-size: 14px;
  color: #909090;
}

.fullName {
  font-size: 16px;
}

.created {
  font-size: 12px;
  color: #909090;
}

.titlePackage {
  font-size: 24px;
  display: flex;
  flex-direction: row;
}

.packageCarousel {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  border: 0.5 solid #000;
  background-color: #ff6304;
  bottom: 16px;
  cursor: pointer;
}

.packageCarousel:hover {
  background-color: rgb(255, 235, 235) !important;
}

.packageImages {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.packagePrice {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 8px;

  .aed {
    color: #000;
    font-size: 20px !important;
  }

  .price {
    color: #ff6304;
    font-size: 24px !important;
    margin: 0 8px;
    font-weight: bold;
  }

}

.exclude {
  font-size: 10px !important;
  color: #909090;
  margin-bottom: 16px;
}

.address {
  font-size: 14px !important;
  color: #909090;
  margin-bottom: 8px;
}

.expire_date {
  font-size: 14px !important;
  color: #909090;
  margin-bottom: 16px;
}

.description {
  font-size: 14px;
  color: #909090;
  margin-top: 16px;
  white-space: pre-wrap;
  text-align: justify;
}

.Divider {
  border-top: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
  padding-top: 16px;
  padding-bottom: 16px;
}

.hotDeal {
  // position: absolute;
  // right: 24px;
  margin-left: 8px;
  font-size: 24px;
  color: #ff6304;
}

.bookBtn {
  position: absolute;
  bottom: -40px;
  right: 16px;
  color: rgb(192, 77, 0);
  border-bottom-right-radius: 16px;
}

.effect {
  border-radius: 8px 0 0 8px;
  background: linear-gradient(#FFF, #ff6304);
  opacity: 0.3;
  width: 40%;
  height: 100%;
  position: absolute;
  top: 0px;
}

@media only screen and (max-width: 900px) {
  .card {
    height: 100%;
  }

  .row {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }

  .left {
    width: 100%;
    display: none;
  }

  .right {
    width: 100%;
    padding: 24px;
    overflow: visible;
  }

  .packageImages {
    height: 250px;
    border-radius: 8px 8px 0 0;
    object-fit: contain;
    margin-bottom: 16px;
  }

  .effect {
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 250px;
  }

  .packageCarousel {
    margin-top: 16px;
    position: relative;
  }

}

@media only screen and (min-width: 900px) {
  .bookBtnMobile {
    display: none;
  }

  .ImagesOnMobile {
    display: none;
  }
}