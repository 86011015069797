.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    width: 100%;
    padding: 1.6rem;
    font-size: 1.6rem !important;
    font-weight: 600 !important;
    color: #313a46 !important;
    display: flex;
    justify-content: center;
  }

  .content {
    flex: 1;
    height: 100%;
    overflow: auto;
  }
}
