.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .sideBar {
    width: 27.6rem !important;
    max-width: 27.6rem !important;
    min-width: 27.6rem !important;
    background-color: #313a46;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

    ul {
      padding: 1.2rem 0rem;

      :global {
        .ant-menu-item-selected {
          a {
            color: #fff;
          }
        }

        li.ant-menu-submenu {
          padding: 0rem 3.2rem !important;
        }
      }

      li {
        margin-bottom: 0rem;
        padding: 1.2rem 3.2rem !important;

        a {
          color: #8391a2;
        }
      }
    }
  }

  .header {
    color: #000;
    background: #fff;
    width: 100%;
    z-index: 999;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    padding: 0rem 2rem;
    overflow: auto;
    background-color: #fafbfe;
    position: relative;
  }

  .webHeader {
    padding: 0rem 2.4rem;
  }
}
