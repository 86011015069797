.container {
  display: flex;
  margin-right: 4rem;

  .select {
    :global(.ant-select-selector) {
      padding: 0.4rem 1.2rem !important;
      height: fit-content !important;
      border-radius: 0.4rem !important;
      width: 16rem !important;
    }
  }

  .input {
    width: 12rem;
    margin-left: 2.4rem;
  }

  .actionIcon {
    font-size: 1.6rem;
    color: #6c757d;
    cursor: pointer;
    padding: 0.8rem 0.4rem;
    margin-left: 2.4rem;
  }

  .actionIcon + .actionIcon {
    margin-left: 0.2rem;
  }

  .actionIcon:hover {
    background-color: #fafafa;
    border-radius: 50%;
  }
}

.viewOnlycontainer {
  height: 16rem;
  width: 20rem;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  margin: 1.2rem;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1.6rem;

  .label {
    color: #6c757d;
  }
}
