.modal {
  width: fit-content !important;
  max-width: 72rem;
}
.container {
  display: flex;
  max-height: 36rem;

  .title {
    font-size: 1.6rem !important;
    white-space: pre-wrap;
    text-align: center;
  }

  .datePickerContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 32rem;
  }

  .timeSlotsContainer {
    display: flex;
    flex-direction: column;
    padding: 0rem 1.6rem;
    width: 100%;
    max-width: 32rem;
    height: 100%;

    .actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1.6rem 0rem;

      .actionIcon {
        display: flex;
        font-size: 2rem;
        cursor: pointer;
      }
    }

    .timeSlots {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      height: 26rem;
      overflow: auto;
      padding-right: 0.8rem;
    }

    .timeSlot + .timeSlot {
      margin-top: 1.2rem;
    }

    .timeSlot {
      width: 100%;
      justify-content: space-between;
    }
  }
}
