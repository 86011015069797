.container {
  margin: 2.4rem 0rem;
}

.table {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border-radius: 0.4rem;
}

.heading {
  display: block;
  font-size: 2rem;
  color: black;
  padding: 0rem 2rem;
  margin-bottom: 1.2rem;
}
